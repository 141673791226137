<template>
  <v-card>
    <v-card-title>
      <h2>Summary</h2>
    </v-card-title>
    <v-card-text>
      <v-layout wrap dense style="margin-bottom: 18px; font-size: 16px">
        <template v-if="total !== amountDueToday">
          <v-flex xs8 class="gray-lighter">
            <h2 style="font-size: 16px">Grand Total</h2>
          </v-flex>
          <v-spacer />
          <v-flex xs4 class="gray-lighter">
            <h2 style="font-size: 16px">
              {{ currencyFilter(quote.grandRecurringTotal || total) }}
            </h2>
          </v-flex>
          <v-spacer />
        </template>

        <template v-for="(trip, tripIndex) in quote.trips">
          <v-flex
            :key="`checkout-charterup-payment-trip-${tripIndex + 1}-label`"
            xs8
          >
            <span v-if="trip.routeName">{{ trip.routeName }}</span>
            <span v-else>Trip #{{ tripIndex + 1 }}</span>
            <span v-if="trip.paymentType.key === 'full_payment'">
              Full Payment
            </span>
            <span v-else>
              Deposit ({{ parseInt(trip.depositPercentage, 10) }}%)
            </span>
          </v-flex>

          <v-spacer
            :key="`checkout-charterup-payment-trip-${tripIndex + 1}-spacer`"
          />
          <v-flex
            :key="`checkout-charterup-payment-trip-${tripIndex + 1}-value`"
            xs4
          >
            <span v-if="trip.paymentType.key === 'full_payment'">
              {{ currencyFilter(trip.recurringTripTotal || trip.total) }}
            </span>
            <span v-else>
              {{
                currencyFilter(
                  trip.recurringAmountDueNow || getAmountDueTodayForTrip(trip)
                )
              }}
            </span>
          </v-flex>
        </template>

        <v-flex xs8>
          <h2 v-if="total === amountDueToday" style="font-size: 16px">
            Grand Total
          </h2>
          <h2 v-else style="font-size: 16px">Amount Due Today</h2>
        </v-flex>
        <v-spacer />
        <v-flex xs4>
          <h2 style="font-size: 16px">
            {{
              currencyFilter(quote.recurringAmountDueToday || amountDueToday)
            }}
          </h2>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/string'
import CheckoutMixin from '@/components/CheckoutMixin'

export default {
  name: 'BillingSummary',
  mixins: [CheckoutMixin],
  props: {
    cancellationPolicy: {
      type: Boolean,
    },
    quote: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    dueDate() {
      const then = DateTime.fromISO(
        this.quote?.trips?.[0]?.dueDate
      ).toLocaleString({
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
      return then || ''
    },
  },
  methods: {
    currencyFilter,
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.gray-lighter h2 {
  color: $gray-light !important;
}
</style>
