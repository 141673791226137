
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask'
import { DateTime } from 'luxon'
import { currencyFilter, pluralize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'
import { CreditCardType, PaymentMethodTypeId, PaymentMethodTypeKey } from '@/utils/enum'
import { finixConfig } from '@/utils/env'
import { Stop, Trip, Quote, StagePaymentMethod } from '@/models/dto'

import 'typeface-barlow'
import 'typeface-montserrat'
import 'typeface-poppins'
import 'typeface-fugaz-one'

import StarRating from 'vue-star-rating'

import ShofurFooter from '@/components/ShofurFooter.vue'
import GoGoFooter from '@/components/GoGoFooter.vue'
import NationalFooter from '@/components/NationalFooter.vue'
import ShofurHeader from '@/components/ShofurHeader.vue'
import NationalHeader from '@/components/NationalHeader.vue'
import GoGoHeader from '@/components/GoGoHeader.vue'

import trustPilot from '@/assets/images/logo-trustpilot.svg'
import reseller from '@/assets/images/logo-reseller-ratings.png'
import googleLogo from '@/assets/images/logo-google.png'

import CheckoutMixin from '@/components/CheckoutMixin'

const CUSTOMER_NOTE_TYPE = 2

const faviconMap = {
  national: 'https://www.nationalbuscharter.com/images/national_favicon.png',
  gogo: 'https://gogocharters.com/img/gogo_favicon.png',
  shofur: 'https://shofur.com/images/favicon.png',
}

const checkoutStyles = {
  shofur: {
    backgroundColor: '#f4f4f4',
    headerColor: '#e2e2e2',
    headerColor2: '#e2e2e2',
    headerColor3: '#696969',
    buttonColor: '#8ec63d',
    fontFamily: 'Montserrat',
    metaTitle: 'Shofur LLC',
    reviews: [
      {
        reviewer: ' Scott Snow, Spirit Airlines',
        text:
          'Thank you so much for being able to get us so many buses last minute!',
      },
      {
        reviewer: 'Ginelle Charles, NFL',
        text:
          'I truly appreciate all the attention to detail and the patience that you had with us.',
      },
      {
        reviewer: 'Kizo M.',
        text:
          'Enjoyable ride through the city of Atlanta. The bus was new, comfortable, cool and clean. The staff are friendly and helpful.',
      },
    ],
    header: ShofurHeader,
    footer: ShofurFooter,
  },
  gogo: {
    fontFamily: 'Poppins',
    backgroundColor: '#f2f6f9',
    headerColor: '#e4edf4',
    headerColor2: '#42516d',
    headerColor3: '#26b4cb',
    buttonColor: '#fd6a22',
    metaTitle: 'GOGO Charters LLC',
    reviews: [
      {
        logo: trustPilot,
        count: 205,
        key: 'trustpilot',
      },
      {
        logo: reseller,
        count: 189,
        key: 'reseller',
      },
      {
        logo: googleLogo,
        count: 596,
        key: 'google',
      },
    ],
    header: GoGoHeader,
    footer: GoGoFooter,
  },
  national: {
    fontFamily: 'Barlow',
    backgroundColor: '#f2f6f9',
    headerFontFamily: 'Fugaz One',
    headerColor: '#e4edf4',
    headerColor2: '#7485a3',
    headerColor3: '#42516d',
    buttonColor: '#186db5',
    metaTitle: 'National Bus Charter',
    reviews: [
      {
        reviewer: 'Ben R.',
        text:
          'I booked a trip from Los Angeles to San Diego this past weekend. The quote process was quick and painless and everything went smoothly.',
      },
      {
        reviewer: 'Adam L.',
        text:
          'The bus tracking through the app is helpful when locating the buses we have reserved. We are also able to contact the driver directly through the app during the trip to provide specific directions or requests.',
      },
      {
        reviewer: 'Joe D.',
        text:
          'I was able to find pricing right in my budget and still being provided a quality bus! I saved so much time comparing all the prices right in front of me on my computer!',
      },
    ],
    header: NationalHeader,
    footer: NationalFooter,
  },
}

const finixOptions = {
  showAddress: true,   // show address fields in the form (default is false)
  showLabels: true,  //show labels in the form (default is true)
  labels: {   // set custom labels for each field
    name: "Name", // Supported Fields: "name", "number", "expiration_date", "security_code", "address_line1", "address_line2", "address_city", "address_state", "address_region", "address_country", "address_postal_code"
    security_code: "Security Code",
    address_line1: "Address"
  },
  showPlaceholders: false,   // turn on or off placeholder text in the fields (default is true)
  hideFields: [   // set custom placeholders for each field, you can specify them here
    "address_line2",
    "address_region",
    "address_country",
  ], // Fields available to hide: "name", "security_code", "address_line1", "address_line2", "address_city", "address_state", "address_region", "address_country", "address_postal_code", "address_country"
  requiredFields: [   // require any specific fields that are not required by default, you can specify them here
    "name",
    "address_line1",
    "address_state",
    "address_city",
    "address_postal_code",
  ], // Supported Fields: "name", "address_line1", "address_line2", "address_city", "address_state", "address_region", "address_country", "address_postal_code"
  hideErrorMessages: false,   // if you want to require a field, but not hide input error messages (default is false)
  errorMessages: {   // set custom error messages for each field if you are showing error messages
    name: "Please enter a valid name", // Supported Fields: "name", "number", "expiration_date", "security_code", "address_line1", "address_line2", "address_city", "address_state", "address_region", "address_country", "address_postal_code"
    address_city: "Please enter a valid city",
  },
  styles: {    // default styling for all fields
    default: {
      color: "#000",
      border: "1px solid #6c6f76",
      borderRadius: "4px",
      padding: "8px 16px",
      fontFamily: "Helvetica",
      fontSize: "16px",
    },
    success: {     // specific styling if the field is valid
      color: "#000",
    },
    error: {
      color: "#d9534f",    // specific styling if the field has errors
      border: "1px solid rgba(255,0,0, 0.3)",
    },
  },
}

@Component({
  directives: {
    mask,
  },
  components: {
    ShofurHeader,
    ShofurFooter,
    NationalHeader,
    NationalFooter,
    GoGoHeader,
    GoGoFooter,
    StarRating,
  },
  filters: {
    phoneFormatFilter,
    itineraryDateFilter(datetime, zone) {
      return DateTime.fromISO(datetime, { zone })
        .toLocaleString(DateTime.DATETIME_SHORT)
        .split(',')?.[0]
    },
    itineraryTimeFilter(datetime, zone) {
      return DateTime.fromISO(datetime, { zone })
        .toLocaleString(DateTime.DATETIME_SHORT)
        .split(',')?.[1]
    },
  },
  mixins: [CheckoutMixin]
})
export default class CheckoutBrokerForm extends Vue {

  @Prop({ type: Object, default: () => ({}) }) readonly quote: Quote;
  @Prop({ type: String, default: '' }) readonly terms!: string;
  @Prop({ type: Boolean }) readonly isSubmitting!: boolean;
  @Prop({ type: String, default: '' }) readonly currencyType!: string;
  @Prop({ type: Number, required: true }) readonly exchangeRate!: number;

  phoneFormatFilter = phoneFormatFilter
  pluralize = pluralize

  finixForm: any = null
  formIsValid: boolean = false
  billingAddressFormIsValid: boolean = false
  selectedPaymentMethodTypeId: PaymentMethodTypeId = null
  providerId: number = null
  agree: boolean = false
  paymentServiceError: string = ''
  firstName = {
    value: '',
    rules: [(v) => !!v || 'First name is required'],
  }
  lastName = {
    value: '',
    rules: [(v) => !!v || 'Last name is required'],
  }
  email = {
    value: '',
    rules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
  }
  phone = {
    value: '',
    mask: '+1 (###) ###-####',
    rules: [(v) => !!v || 'Phone number is required'],
  }

  cardName = {
    value: '',
    rules: [(v) => !!v || 'Name is required'],
  }
  cardNumber = {
    value: '',
    errorMessages: null,
    rules: [
      (v) => !!v || 'Card number is required',
      (v) => v.length >= 15 || 'Card number is not valid',
    ],
  }
  cardExpirationMonth = {
    value: '01',
    rules: [(v) => !!v || 'Card expiration month is required'],
    errorMessages: null,
    options: (() => {
      const months = []
      for (let i = 1; i <= 12; i++) {
        if (i < 10) {
          months.push('0' + i.toString())
        } else {
          months.push(i.toString())
        }
      }
      return months
    })(),
  }
  cardExpirationYear = {
    value: DateTime.local().year.toString(),
    rules: [(v) => !!v || 'Card expiration year is required'],
    errorMessages: null,
    options: (() => {
      const currentYear = DateTime.local().year;
      return Array.from({ length: 10 }, (_, i) => (currentYear + i).toString());
    })(),
  }
  cardCVV = {
    value: '',
    masks: {
      default: '###',
      visa: '###',
      mastercard: '###',
      discover: '###',
      amex: '####',
      diners: '###',
    },
    errorMessages: null,
    rules: [
      (v) => !!v || 'Card CVV is required',
      (v) => v.length >= 3 || 'Card CVV is not valid',
    ],
  }

  address1 = {
    value: '',
    rules: [(v) => !!v || 'Address is required'],
  }
  zip = {
    value: '',
    rules: [
      (v) => !!v || 'Zip code is required',
      (v) => (v.length >= 5 && v.length <= 10) || 'Zip code is not valid',
    ],
  }
  city = {
    value: '',
    rules: [(v) => !!v || 'City is required'],
  }
  paymentMethodTypeIdToKey = {
    1: PaymentMethodTypeKey.CreditCard,
    2: PaymentMethodTypeKey.ACH,
    3: PaymentMethodTypeKey.Check,
  }

  async mounted(): Promise<void> {
    this.initFinixForm()
    if (faviconMap?.[this.checkoutType]) {
      this.changeFavicon(faviconMap[this.checkoutType])
    }
  }

  get cardNumberMask() {
    if (this.cardType === 'amex') {
      return '#### ###### #####'
    }
    if (this.cardType === 'diners') {
      return '#### ###### ####'
    }
    return '#### #### #### ####'
  }

  get cardType(): CreditCardType {
    if (this.cardNumber?.value?.[0] === '5') {
      return CreditCardType.Mastercard
    } else if (this.cardNumber?.value?.[0] === '4') {
      return CreditCardType.Visa
    } else if (this.cardNumber?.value?.[0] === '6') {
      return CreditCardType.Discover
    } else if (
      ['34', '37'].includes(this.cardNumber?.value?.substring(0, 2))
    ) {
      return CreditCardType.AmericanExpress
    } else if (
      ['30', '36', '38'].includes(this.cardNumber?.value?.substring(0, 2))
    ) {
      return CreditCardType.Diners
    } else {
      return CreditCardType.Default
    }
  }

  get cardNumberRawValue() {
    return this.cardNumber.value.split(' ').join('')
  }

  get header() {
    return this.checkoutStyle?.header
  }

  get checkoutType() {
    return this.quote?.checkoutPage?.key
  }

  get checkoutStyle() {
    return checkoutStyles[this.checkoutType]
  }

  get reviews() {
    return this.checkoutStyle?.reviews
  }

  get buttonColor() {
    return this.checkoutStyle?.buttonColor
  }

  get backgroundColor() {
    return this.checkoutStyle?.backgroundColor
  }

  get headerColor() {
    return this.checkoutStyle?.headerColor
  }

  get headerColor2() {
    return this.checkoutStyle?.headerColor2
  }

  get headerColor3() {
    return this.checkoutStyle?.headerColor3
  }

  get startDate() {
    const then = DateTime.fromISO(
      this.quote?.trips?.[0]?.startDate
    ).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })
    return then || ''
  }

  get allTripsFullPayment() {
    return (
      this.quote?.trips?.filter(
        (trip) => trip.paymentType.key !== 'full_payment'
      )?.length === 0
    )
  }

  get onlyOneTrip() {
    return this.quote?.trips?.length === 1
  }

  get stopNotes() {
    return this.quote.trips.map((trip) => {
      let tripStops = [...trip.stops]
      tripStops = tripStops.map((stop) => ({ ...stop, active: false }))
      return { stops: tripStops }
    })
  }

  get checkoutPaymentMethods(): StagePaymentMethod[] {
    return this.quote?.trips?.[0]?.stagePaymentMethods?.checkoutPaymentMethods ||
      []
  }

  get showCreditCardInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.CreditCard
  }

  get showCheckInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.Check
  }

  get showACHInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.ACH
  }

  get showBillingAddressForm() {
    return this.showCheckInput || this.showACHInput
  }

  get isPaymentMethodSelectionNeeded(): boolean {
    return (
      this.isPaymentMethodAllowed(PaymentMethodTypeId.CreditCard) &&
      (this.isPaymentMethodAllowed(PaymentMethodTypeId.ACH)
        || this.isPaymentMethodAllowed(PaymentMethodTypeId.Check))
    )
  }

  get activePaymentMethod(): PaymentMethodTypeKey {
    if (this.selectedPaymentMethodTypeId) {
      return this.paymentMethodTypeIdToKey[this.selectedPaymentMethodTypeId]
    }
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.CreditCard)) {
      return PaymentMethodTypeKey.CreditCard
    }
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.ACH)) {
      return PaymentMethodTypeKey.ACH
    }
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.Check)) {
      return PaymentMethodTypeKey.Check
    }
    return null
  }

  get paymentMethodSelectOptions(): PaymentMethodTypeId[] {
    const options = []
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.CreditCard)) {
      options.push(PaymentMethodTypeId.CreditCard)
    }

    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.Check)) {
      options.push(PaymentMethodTypeId.Check)
    } else if (this.isPaymentMethodAllowed(PaymentMethodTypeId.ACH)) {
      options.push(PaymentMethodTypeId.ACH)
    }
    return options
  }

  isPaymentMethodAllowed(paymentMethodTypeId: number): boolean {
    if (this.checkoutPaymentMethods.length === 0) {
      return false
    }
    const paymentMethod = this.checkoutPaymentMethods.find(
      (method) => method.paymentMethodId === paymentMethodTypeId
    )
    return paymentMethod.isAllowed === 1
  }

  getTabLabel(paymentMethodTypeId: number): string {
    if (paymentMethodTypeId === PaymentMethodTypeId.CreditCard) {
      return 'Credit Card'
    }
    if (paymentMethodTypeId === PaymentMethodTypeId.ACH
        || paymentMethodTypeId === PaymentMethodTypeId.Check) {
      return 'Check / Wire'
    }
  }

  initFinixForm(): void {
    //@ts-ignore
    this.finixForm = window.Finix.CardTokenForm("finix-form", finixOptions);
  }

  async tokenizeFinixCard(): Promise<string> {
    const { environment, applicationId } = finixConfig();

    return new Promise((resolve, reject) => {
      this.finixForm.submit(environment, applicationId, function (err, res) {
        if (err) {
          reject(err); // Reject the promise if there's an error
        } else {
          const tokenData = res.data || {};
          const token = tokenData.id;
          resolve(token); // Resolve the promise with the token
        }
      });
    });
  }

  tripPaymentTypeText(trip: Trip): string {
    if (trip?.paymentType?.key === 'down_payment') {
      return `Deposit (${parseInt(trip.depositPercentage, 10)}%)`
    }
    return trip?.paymentType?.label
  }

  recurrenceEndDate(trip: Trip): DateTime {
    return trip?.recurrences?.[0]?.endDate || trip.startDate
  }

  getStopNotes(stop: Stop): string {
    if (stop.stopNotes) {
      const activeNotes = stop.stopNotes.filter((note) => note.active)
      if (activeNotes.length) {
        return activeNotes[0].html || activeNotes[0].note
      }
    }
    return stop.notes
  }

  getTripNotes(trip: Trip): string {
    return (
      trip?.tripNotes?.find((note) => note.noteType === CUSTOMER_NOTE_TYPE)
        ?.html || trip?.description
    )
  }

  priceFilter(amount: number): string {
    if (this.currencyType === 'USD') {
      return currencyFilter(amount)
    }
    return `${currencyFilter(amount * this.exchangeRate)} ${this.currencyType} (${currencyFilter(amount)} USD)`
  }

  changeFavicon(src: string): void {
    const head = document.head || document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    const oldLink = document.getElementById('dynamic-favicon')
    link.id = 'dynamic-favicon'
    link.rel = 'shortcut icon'
    link.href = src
    if (oldLink) {
      head.removeChild(oldLink)
    }
    head.appendChild(link)
  }

  toggleStopNotes(tripIndex: number, stopIndex: number): void {
    this.stopNotes[tripIndex].stops[stopIndex].active = !this.stopNotes[
      tripIndex
    ].stops[stopIndex].active
  }

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }

    if (!this.agree) {
      return
    }

    let paymentMethodData = null
    if (this.showCreditCardInput) {

      const token = await this.tokenizeFinixCard()
      paymentMethodData = {
        activeMethod: this.activePaymentMethod,
        finixToken: token
      }

      this.$emit('submit', paymentMethodData)
      return

    } else {
      const valid = (this.$refs['form-billing-address'] as any).validate()
      if (!valid) {
        return
      }
    }

    this.paymentServiceError = ''

    paymentMethodData = {
      activeMethod: this.activePaymentMethod,
      name: this.cardName.value,
      cardholderName: this.cardName.value,
      cardNumber: this.cardNumberRawValue,
      mask: this.cardNumberRawValue.substr(
        this.cardNumberRawValue.length - 4,
        4
      ),
      securityCode: this.cardCVV.value,
      exp_date: `${this.cardExpirationMonth.value}/${this.cardExpirationYear.value}`,
      expirationMonth: this.cardExpirationMonth.value,
      expirationYear: this.cardExpirationYear.value,
      type_label: this.cardType,
      address: {
        name: '',
        street1: this.address1.value,
        street2: '',
        city: this.city.value,
        state: null,
        postal_code: this.zip.value,
        lat: null,
        lng: null,
        title: null,
        time_zone: DateTime.local().zoneName,
        country: 'US',
      },
    }

    this.$emit('submit', paymentMethodData)
  }
}
