<template>
  <v-form
    ref="form"
    v-model="formIsValid"
    class="charterup"
    @submit.prevent="submit"
  >
    <CharterUpToolbar>
      Quote ID: {{ quote.quoteId }}
    </CharterUpToolbar>
    <TwoColumnLayout
      v-if="!quoteDetailLoading && quote"
      :left-column="6"
      max-width="1100px"
    >
      <template #left-column>
        <v-card class="solid">
          <v-card-title>
            <h2>Payment Information</h2>
          </v-card-title>

          <v-card-text>
            <v-btn-toggle
              v-if="
                paymentMethodAllowed('credit_card') &&
                paymentMethodAllowed('check')
              "
              v-model="activePaymentMethod"
              class="elevation-0 toggle-buttons"
              mandatory
            >
              <v-btn
                v-if="paymentMethodAllowed('credit_card')"
                flat
                value="credit_card"
              >
                Credit Card
              </v-btn>
              <v-btn v-if="paymentMethodAllowed('check')" flat value="check">
                Check
              </v-btn>
            </v-btn-toggle>

            <template v-if="activePaymentMethod === 'check'">
              <div class="pb-4 pt-0">
                <p>
                  After completion of this checkout an invoice will be emailed
                  to you with instructions for wire transfer or mailing check.
                </p>
              </div>
            </template>

            <template v-else-if="activePaymentMethod === 'credit_card'">
              <v-layout row wrap no-gutters>
                <v-flex xs12>
                  <label for="cardName">Name on Card</label>
                  <v-text-field
                    id="cardName"
                    v-model="cardName.value"
                    :rules="cardName.rules"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                  />
                </v-flex>

                <v-flex xs12>
                  <label for="cardNumber">Card Number</label>
                  <v-text-field
                    id="cardNumber"
                    ref="cardNumber"
                    v-mask="cardNumberMask"
                    :value="cardNumber.value"
                    :rules="cardNumber.rules"
                    :error-messages="cardNumber.errorMessages"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                    @input="(value) => (cardNumber.value = value)"
                    @focus="
                      cardNumber.errorMessages = null
                      paymentServiceError = ''
                    "
                  >
                    <template #append>
                      <v-icon
                        class="fade-in"
                        style="width: 38px; height: 38px; margin-right: 12px"
                      >
                        $vuetify.icons.creditcard-{{ cardType }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-flex>

                <v-flex xs6 style="padding-right: 10px">
                  <label for="cardExpiration">Exp. Date (mm/yyyy)</label>
                  <v-text-field
                    id="cardExpiration"
                    v-model="cardExpiration.value"
                    v-mask="
                      cardExpiration.masks[cardExpirationLength || 'default']
                    "
                    :rules="cardExpiration.rules"
                    :error-messages="cardExpiration.errorMessages"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                    @focus="
                      cardExpiration.errorMessages = null
                      paymentServiceError = ''
                    "
                  />
                </v-flex>

                <v-flex xs6 style="padding-left: 10px">
                  <label for="cardCVV">Security Code (CVV)</label>
                  <v-text-field
                    id="cardCVV"
                    v-model="cardCVV.value"
                    v-mask="cardCVV.masks[cardType]"
                    :rules="cardCVV.rules"
                    :error-messages="cardCVV.errorMessages"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                    @focus="
                      cardCVV.errorMessages = null
                      paymentServiceError = ''
                    "
                  />
                </v-flex>
              </v-layout>
            </template>
          </v-card-text>
        </v-card>

        <v-card class="solid">
          <v-card-title>
            <h2>Billing Address</h2>
          </v-card-title>

          <v-card-text v-if="activePaymentMethod === 'check'">
            <v-form
              ref="form-billing-address"
              v-model="billingAddressFormIsValid"
            >
              <v-layout row wrap no-gutters>
                <v-flex xs12>
                  <label for="address1">Address</label>
                  <v-text-field
                    id="address1"
                    v-model="address1.value"
                    :rules="address1.rules"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                  />
                </v-flex>

                <v-flex xs6 style="padding-right: 10px">
                  <label for="city">City</label>
                  <v-text-field
                    id="city"
                    v-model="city.value"
                    :rules="city.rules"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                  />
                </v-flex>

                <v-flex xs6 style="padding-left: 10px">
                  <label for="zip">Zip Code</label>
                  <v-text-field
                    id="zip"
                    v-model="zip.value"
                    :rules="zip.rules"
                    required
                    validate-on-blur
                    flat
                    outline
                    solo
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-card-text v-else-if="activePaymentMethod === 'credit_card'">
            <v-layout row wrap no-gutters>
              <v-flex xs12>
                <label for="address1">Address</label>
                <v-text-field
                  id="address1"
                  v-model="address1.value"
                  :rules="address1.rules"
                  required
                  validate-on-blur
                  flat
                  outline
                  solo
                />
              </v-flex>

              <v-flex xs6 style="padding-right: 10px">
                <label for="city">City</label>
                <v-text-field
                  id="city"
                  v-model="city.value"
                  :rules="city.rules"
                  required
                  validate-on-blur
                  flat
                  outline
                  solo
                />
              </v-flex>

              <v-flex xs6 style="padding-left: 10px">
                <label for="zip">Zip Code</label>
                <v-text-field
                  id="zip"
                  v-model="zip.value"
                  :rules="zip.rules"
                  required
                  validate-on-blur
                  flat
                  outline
                  solo
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <div v-if="quote && !$vuetify.breakpoint.mdAndUp">
          <TripCollectionCard
            style="margin-bottom: 12px; background-color: white"
            :trips="quote.trips"
            :created-on="quote.createdOn"
            itinerary
          />

          <BillingSummary
            :quote="quote"
            :cancellation-policy="showCancellationPolicy"
          />
        </div>

        <AlertBox
          :active="!!paymentServiceError"
          type="error"
          style="margin-top: 12px"
        >
          {{ paymentServiceError }}
        </AlertBox>

        <v-layout
          row
          justify="center"
          style="margin-top: 24px; text-align: center"
        >
          <v-flex
            class="justify-center"
            :style="$vuetify.breakpoint.mdAndUp ? {} : { 'font-size': '11px' }"
          >
            <span style="font-size: 16px">
              By clicking the button, you agree to our
              <a
                id="checkout-link-terms-and-conditions"
                href="https://www.charterup.com/transportation-terms"
                target="_blank"
                class="unassuming-link"
              >
                Terms &amp; Conditions
              </a>
              .
            </span>

            <v-flex class="d-flex justify-center">
              <v-btn
                id="checkout-button-reserve-now"
                block
                large
                type="submit"
                class="primary button-submit"
                :disabled="isSubmitting"
                :loading="isSubmitting"
                style="margin-top: 8px; margin-bottom: 16px"
              >
                Reserve Now
              </v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
        <br />
        <v-layout row wrap style="font-size: 14px; text-align: center">
          <v-flex xs12 sm4 mb-4>
            <div style="min-height: 40px; margin-bottom: 10px">
              <v-icon style="width: 35px; height: 35px">
                $vuetify.icons.color-phone
              </v-icon>
            </div>
            <h2 style="font-size: 16px">24/7 Support</h2>
            <p>Award winning customer support is here for you.</p>
          </v-flex>

          <v-flex xs12 sm4 mb-4>
            <div style="min-height: 40px; margin-bottom: 10px">
              <v-icon style="width: 40px; height: 40px">
                $vuetify.icons.color-marker
              </v-icon>
            </div>
            <h2 style="font-size: 16px">Live Tracking</h2>
            <p>Real-time bus tracking the day of your trip.</p>
          </v-flex>

          <v-flex xs12 sm4 mb-4>
            <div style="min-height: 40px; margin-bottom: 10px">
              <v-icon style="width: 38px; height: 38px">
                $vuetify.icons.color-progress
              </v-icon>
            </div>
            <h2 style="font-size: 16px">Unbeatable Prices</h2>
            <p>Largest marketplace for charter buses in the U.S.</p>
          </v-flex>
        </v-layout>
      </template>

      <template #right-column>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <TripCollectionCard
            style="margin-bottom: 24px; background-color: white"
            :trips="quote.trips"
            :created-on="quote.createdOn"
            itinerary
          />

          <BillingSummary
            :quote="quote"
            :cancellation-policy="showCancellationPolicy"
          />
        </div>
      </template>
    </TwoColumnLayout>
    <CharterUpFooter />
  </v-form>
</template>

<script>
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import CharterUpToolbar from '@/components/CharterUpToolbar.vue'
import CharterUpFooter from '@/components/CharterUpFooter.vue'

import { mask } from 'vue-the-mask'

import { Duration, DateTime } from 'luxon'
import { currencyFilter, pluralize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'

import TripCollectionCard from '@/components/CharterUpTripCollectionCard.vue'
import BillingSummary from '@/components/CharterUpBillingSummary.vue'

import CheckoutMixin from '@/components/CheckoutMixin'

export default {
  components: {
    CharterUpToolbar,
    CharterUpFooter,
    TripCollectionCard,
    BillingSummary,
    TwoColumnLayout,
  },
  directives: {
    mask,
  },
  mixins: [CheckoutMixin],
  metaInfo() {
    this.changeFavicon(require('@/assets/images/icon-charterup.svg'))
    return {
      title: 'Checkout - CharterUP',
    }
  },
  props: {
    quote: {
      type: Object,
      default: () => ({
        company: {
          address: {},
        },
        customer: {},
        trips: [],
        notes: [],
      }),
    },
    terms: {
      type: String,
      default: '',
    },
    isSubmitting: {
      type: Boolean,
    },
  },
  data() {
    return {
      formIsValid: false,
      billingAddressFormIsValid: false,
      activePaymentMethod: '',
      availablePaymentMethods: [
        {
          text: 'Credit Card',
          value: 'credit_card',
        },
        {
          text: 'Check',
          value: 'check',
        },
      ],
      agree: false,
      quoteDetailLoading: false,
      firstName: {
        value: '',
        rules: [(v) => !!v || 'First name is required'],
      },
      lastName: {
        value: '',
        rules: [(v) => !!v || 'Last name is required'],
      },
      email: {
        value: '',
        rules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
      phone: {
        value: '',
        mask: '+1 (###) ###-####',
        rules: [(v) => !!v || 'Phone number is required'],
      },
      cardName: {
        value: '',
        rules: [(v) => !!v || 'Name is required'],
      },
      cardNumber: {
        value: '',
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card number is required',
          (v) => v.length >= 15 || 'Card number is not valid',
        ],
      },
      cardExpiration: {
        value: '',
        masks: {
          default: '#/####',
          longer: '##/####',
        },
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card expiration date is required',
          (v) => v.length >= 5 || 'Card expiration is not valid',
        ],
      },
      cardCVV: {
        value: '',
        masks: {
          default: '###',
          visa: '###',
          mastercard: '###',
          discover: '###',
          amex: '####',
          diners: '###',
        },
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card CVV is required',
          (v) => v.length >= 3 || 'Card CVV is not valid',
        ],
      },
      paymentServiceError: '',
      address1: {
        value: '',
        rules: [(v) => !!v || 'Address is required'],
      },
      zip: {
        value: '',
        rules: [
          (v) => !!v || 'Zip code is required',
          (v) => (v.length >= 5 && v.length <= 10) || 'Zip code is not valid',
        ],
      },
      city: {
        value: '',
        rules: [(v) => !!v || 'City is required'],
      },
    }
  },
  computed: {
    showCancellationPolicy() {
      const sevenDays = Duration.fromObject({ days: -14 })
      const dt = DateTime.fromISO(
        this.quote?.trips?.[0]?.stops?.[0]?.pickupDatetime
      ).plus(sevenDays)
      const now = DateTime.local()
      return now < dt
    },
    expirationMonth() {
      if (this.cardExpirationLength === 'longer') {
        return this.cardExpiration.value.substring(0, 2)
      }
      return this.cardExpiration.value.substring(0, 1)
    },
    expirationYear() {
      if (this.cardExpirationLength === 'longer') {
        return this.cardExpiration.value.substring(3)
      }
      return this.cardExpiration.value.substring(2)
    },

    cardNumberMask() {
      if (this.cardType === 'amex') {
        return '#### ###### #####'
      }
      if (this.cardType === 'diners') {
        return '#### ###### ####'
      }
      return '#### #### #### ####'
    },
    cardType() {
      if (this.cardNumber?.value?.[0] === '5') {
        return 'mastercard'
      } else if (this.cardNumber?.value?.[0] === '4') {
        return 'visa'
      } else if (this.cardNumber?.value?.[0] === '6') {
        return 'discover'
      } else if (
        ['34', '37'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'amex'
      } else if (
        ['30', '36', '38'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'diners'
      } else {
        return 'default'
      }
    },
    cardNumberRawValue() {
      return this.cardNumber.value.split(' ').join('')
    },
    cardExpirationRawValue() {
      return this.cardExpiration.value.split('/').join('')
    },
    cardExpirationLength() {
      if (this.cardExpirationRawValue?.[0] === '0') {
        return 'longer'
      }
      if (this.cardExpirationRawValue?.[0] === '1') {
        if (
          this.cardExpirationRawValue?.[1] === '0' ||
          this.cardExpirationRawValue?.[1] === '1'
        ) {
          return 'longer'
        }
        if (this.cardExpirationRawValue?.[1] === '2') {
          if (this.cardExpirationRawValue?.[2] !== '0') {
            return 'longer'
          }
        }
      }
      return 'default'
    },
  },
  async created() {
    if (this.paymentMethodAllowed('credit_card')) {
      this.activePaymentMethod = 'credit_card'
    } else if (this.paymentMethodAllowed('check')) {
      this.activePaymentMethod = 'check'
    }
  },
  methods: {
    currencyFilter,
    phoneFormatFilter,
    pluralize,
    changeFavicon(src) {
      const head = document.head || document.getElementsByTagName('head')[0]
      const link = document.createElement('link')
      const oldLink = document.getElementById('dynamic-favicon')
      link.id = 'dynamic-favicon'
      link.rel = 'shortcut icon'
      link.href = src
      if (oldLink) {
        head.removeChild(oldLink)
      }
      head.appendChild(link)
    },
    paymentMethodAllowed(type) {
      return this.quote?.trips[0]?.paymentMethods?.find((method) => {
        return method.isAllowed && method.paymentMethodType?.key === type
      })
    },

    clearErrorMessages() {
      this.cardNumber.errorMessages = null
      this.cardExpiration.errorMessages = null
      this.paymentServiceError = ''
    },
    submit() {
      if (this.activePaymentMethod === 'credit_card') {
        const valid = this.$refs.form.validate()
        if (!valid) {
          return
        }
      } else {
        const valid = this.$refs['form-billing-address'].validate()
        if (!valid) {
          return
        }
      }

      this.clearErrorMessages()

      const paymentMethodData = {
        activeMethod: this.activePaymentMethod, // either 'credit_card' or 'check' depending on some tab.
        name: this.cardName.value,
        cardholderName: this.cardName.value,
        cardNumber: this.cardNumberRawValue,
        mask: this.cardNumberRawValue.substr(
          this.cardNumberRawValue.length - 4,
          4
        ),
        securityCode: this.cardCVV.value,
        exp_date: `${this.expirationMonth}/${this.expirationYear}`,
        expirationMonth: this.expirationMonth,
        expirationYear: this.expirationYear,
        type_label: this.cardType,
        address: {
          name: '',
          street1: this.address1.value,
          street2: '',
          city: this.city.value,
          state: null,
          postal_code: this.zip.value,
          lat: null,
          lng: null,
          title: null,
          time_zone: DateTime.local().zoneName,
          country: 'US',
        },
      }
      this.$emit('submit', paymentMethodData)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/charterup/main';

.coachrail .charterup {
  h2 {
    border-bottom: 0 !important;
  }

  .container {
    padding: 24px !important;
    max-width: 1100px !important;
  }

  .v-card {
    border-radius: 5px !important;
    border: 1px solid $border-gray !important;
  }

  .v-btn-toggle {
    border-radius: 5px;
    position: relative;
    top: -8px;
    margin-bottom: 12px;

    .v-btn--active .v-btn__content {
      color: $white !important;
    }
  }

  .unassuming-link {
    color: $gray !important;
    text-decoration-line: underline !important;
  }

  .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
    border-width: 1px;
    border-color: rgb(230, 232, 234);
    background-color: $white !important;
  }

  .theme--light.v-text-field--outline.v-input--is-focused:not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot {
    border-width: 1px;
    border-color: $primary;
  }

  .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:hover {
    border-width: 1px;
    border-color: rgb(230, 232, 234);
  }

  .v-text-field__slot input {
    font-family: 'Airbnb Cereal App Book';
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-duration: 0.35s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
  }
}

.coachrail
  .charterup
  .container
  .v-input.v-text-field:not(.v-textarea)
  .v-input__control
  .v-input__slot
  input {
  margin: 0 !important;
}

.coachrail .charterup .container .v-btn {
  font-family: Airbnb Cereal App Book;
  &.primary {
    background-color: rgb(0, 166, 242);
    color: $white;
    .v-btn__content {
      color: $white;
      font-family: Airbnb Cereal App Bold;
    }
  }
  &.button-submit {
    font-size: 24px;
    padding: 0 30px;
    text-transform: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 57px;
    min-width: 101px;
    padding: 0 25.3333333333px;
  }
}

.coachrail
  .charterup
  .container
  .v-btn.primary
  .coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__prepend-outer,
.coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__prepend-inner,
.coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__append-inner,
.coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__append-outer,
.coachrail
  .charterup
  .container
  .v-text-field--enclosed
  .v-input__prepend-outer,
.coachrail
  .charterup
  .container
  .v-text-field--enclosed
  .v-input__prepend-inner,
.coachrail .charterup .container .v-text-field--enclosed .v-input__append-inner,
.coachrail
  .charterup
  .container
  .v-text-field--enclosed
  .v-input__append-outer {
  margin-top: 0px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toggle-buttons {
  :first-child {
    border-radius: 3px 0 0 3px !important;
  }

  :last-child {
    border-radius: 0 3px 3px 0 !important;
  }
}
</style>
